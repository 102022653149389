import { ref } from 'vue'

export default function () {
	const files = ref([])
	const MAX_QNT_UPLOAD  = 2;

	function addFiles(newFiles) {
		let newUploadableFiles = [...newFiles].map((file) => new UploadableFile(file, quantity())).filter((file) => !fileExists(file.id))
		if (files.value.length > MAX_QNT_UPLOAD-1) return;
		files.value = files.value.concat(newUploadableFiles)
	}

	function fileExists(otherId) {
		return files.value.some(({ id }) => id === otherId)
	}

	function removeFile(file) {
		const index = files.value.indexOf(file)

		if (index > -1) files.value.splice(index, 1)
	}

	const quantity = () => files.value.length;

	return { files, addFiles, removeFile, quantity}
}

class UploadableFile {
	constructor(file, index) {
		this.file = file
		this.index = index == 0? 'pushpay':'planning-center';
		this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
		this.url = URL.createObjectURL(file)
		this.status = null
	}
}
